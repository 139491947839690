import React from 'react'

function NotFound(){
    return (
        <div>
            404 Error: Page not found.
        </div>
    );
}

export default NotFound;
